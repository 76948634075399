import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import axios from "axios";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/articles/statistics',
    name: 'article-statistics',
    component: () => import('@/views/articles/ArticleStatistics.vue'),
  },
  {
    path: '/articles/search',
    name: 'article-search',
    component: () => import('@/views/Articles.vue'),
  },
  {
    path: '/politicians/search',
    name: 'politicians-search',
    component: () => import('@/views/politicians/Politicians.vue'),
  },
  {
    path: '/topics',
    name: 'topics',
    component: () => import('@/views/articles/TopicSearch.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/auth/account-settings/AccountSettings.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.name !== 'login')) {
    if (store.getters.isLoggedIn) {
      axios.defaults.headers.common.Authorization = `Bearer ${store.state.token}`
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

axios.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  if (error.response.status === 401) {
    // We are unauthoriyzed
    let result = await store.dispatch('logout')
    router.push('/login')
  }
  return Promise.reject(error);
});

export default router
