import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '../utils/config'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const persist = new VuexPersistence({
  storage: window.localStorage
})

const store = new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, payload) {
      state.status = 'success'
      state.token = payload.token
      state.user = payload.user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
  },
  actions: {
    login({commit}, user) {
      const username = user.username
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post(`${config.SERVER_URL}/auth`, user).then(resp => {
          const token = resp.data.access_token
          axios.defaults.headers.common.Authorization = `Bearer ${token}`
          commit('auth_success', {
              'token': token,
              'user': {'username': username}
            }
          )
          resolve(resp)
        })
          .catch(err => {
            commit('auth_error')
            commit('logout')
            reject(err)
          })
      })
    },
    logout({commit}) {
      return new Promise((resolve, reject) => {
        commit('logout')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  },
  modules: {},
  plugins: [persist.plugin]
})

export default store
